var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "admin--urls"
  }, [_c('r-page-header', {
    staticClass: "admin--urls mb-16 mt-8",
    attrs: {
      "label": "Urls"
    }
  }), _c('div', [_vm.fetchingUrlsError ? _c('r-error-message', {
    staticClass: "admin--urls--errormessage",
    attrs: {
      "errors": [_vm.fetchingUrlsError]
    }
  }) : _vm._e(), _c('v-row', {
    staticClass: "py-5"
  }, [_c('v-col', {
    staticClass: "ml-auto",
    attrs: {
      "cols": "auto"
    }
  }, [_c('r-search-input', {
    staticClass: "mr-2",
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "value": _vm.search
    },
    on: {
      "change": _vm.onChangeSearch
    }
  })], 1)], 1), _vm.search ? _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "admin--urls--chip"
  }, [_c('span', {
    staticClass: "admin--urls--label"
  }, [_vm._v(" " + _vm._s(_vm.search) + " ")]), _c('v-icon', {
    staticClass: "admin--urls--icon",
    on: {
      "click": _vm.onRemove
    }
  }, [_vm._v(" close ")])], 1)]) : _vm._e(), _c('r-table', {
    staticClass: "admin--urls--table",
    attrs: {
      "is-selectable": false,
      "columns": _vm.tableColumns,
      "loading": _vm.fetchingUrls,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "rows": _vm.urls,
      "total-rows": _vm.totalUrls
    },
    on: {
      "change:page": function changePage(_ref) {
        var value = _ref.value;
        return _vm.setPage({
          page: value
        });
      },
      "change:page-size": _vm.onChangePageSize,
      "change:page-size-all": _vm.onChangePageSizeAll,
      "sort:asc": function sortAsc(_ref2) {
        var value = _ref2.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'asc'
        });
      },
      "sort:desc": function sortDesc(_ref3) {
        var value = _ref3.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'desc'
        });
      },
      "change:batch-select": function changeBatchSelect(data) {
        return _vm.$emit('change:batch-select', data);
      },
      "change:batch-select-all": function changeBatchSelectAll() {
        return _vm.$emit('change:batch-select-all');
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          attrs: {
            "to": "/admin/urls/".concat(item._id)
          }
        }, [_c('span', {
          staticClass: "admin--urls--name text-truncate",
          attrs: {
            "title": item.name
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])])];
      }
    }, {
      key: "item.url",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('r-chip', {
          attrs: {
            "label": item.url
          }
        })];
      }
    }, {
      key: "item.clients.clientId",
      fn: function fn(_ref6) {
        var _item$clients;

        var item = _ref6.item;
        return [(_item$clients = item.clients) !== null && _item$clients !== void 0 && _item$clients.length ? _c('r-chip', {
          attrs: {
            "label": item.clients[0].clientId.toString(),
            "title": _vm.implode(item.clients, 'clientId', 'clientId')
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.clients.brands",
      fn: function fn(_ref7) {
        var _item$clients2, _item$clients2$, _item$clients2$$brand;

        var item = _ref7.item;
        return [(_item$clients2 = item.clients) !== null && _item$clients2 !== void 0 && (_item$clients2$ = _item$clients2[0]) !== null && _item$clients2$ !== void 0 && (_item$clients2$$brand = _item$clients2$.brands) !== null && _item$clients2$$brand !== void 0 && _item$clients2$$brand.length ? _c('r-chip', {
          attrs: {
            "label": item.clients[0].brands[0].name,
            "title": _vm.implode(item.clients, 'brands', 'name')
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.clients.categories",
      fn: function fn(_ref8) {
        var _item$clients3, _item$clients3$, _item$clients3$$categ;

        var item = _ref8.item;
        return [(_item$clients3 = item.clients) !== null && _item$clients3 !== void 0 && (_item$clients3$ = _item$clients3[0]) !== null && _item$clients3$ !== void 0 && (_item$clients3$$categ = _item$clients3$.categories) !== null && _item$clients3$$categ !== void 0 && _item$clients3$$categ.length ? _c('r-chip', {
          attrs: {
            "label": item.clients[0].categories[0].name,
            "title": _vm.implode(item.clients, 'categories', 'name')
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.tags",
      fn: function fn(_ref9) {
        var _item$tags;

        var item = _ref9.item;
        return [item !== null && item !== void 0 && (_item$tags = item.tags) !== null && _item$tags !== void 0 && _item$tags.length ? _c('r-chip', {
          attrs: {
            "label": item.tags[0].name,
            "title": _vm.implode(item.tags, 'name', 'name')
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.averageRating",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('r-rating', {
          staticClass: "admin--urls--rating",
          attrs: {
            "value": item.averageRating
          }
        })];
      }
    }, {
      key: "item.numReviews",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(item.numReviews) + " ")];
      }
    }, {
      key: "item.lastReviewed",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [item.lastReviewed ? _c('span', [_vm._v(_vm._s(_vm.formatDate(item.lastReviewed)))]) : _c('span', [_vm._v("Never")])];
      }
    }, {
      key: "item.nextScrape",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('span', [_vm._v(_vm._s(_vm.formatUpdateSlot(item.nextScrape)))])];
      }
    }, {
      key: "item.clients.created",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_vm._v(" " + _vm._s(_vm.oldestCreatedDate(item.clients)) + " ")];
      }
    }, {
      key: "item.nonCanonicalUrls",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [item.nonCanonicalUrls.length ? _c('r-chip', {
          attrs: {
            "label": item.nonCanonicalUrls[0].toString(),
            "title": _vm.implode(item.nonCanonicalUrls, 'nonCanonicalUrls', 'nonCanonicalUrls')
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }